import styled from "styled-components";
import { products } from "../data";
import Product from "./Product";
// import { useEffect, useState } from "react";
// import axios from "axios"

const Container = styled.div`
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const Products = () => {

  // const [products, setProducts] = useState([])

  // useEffect(()=>{
  //   const getProducts = async () => {
  //     try {
  //       const res = await axios.get("https://ruskimya-v2-api.vercel.app/api/products")
  //       setProducts(res.data)
  //     } catch (error) {
        
  //     }
  //   }
  //   getProducts()
  // })

  return (
    <Container>
      {products.map((item) => (
        <Product item={item} key={item.id} />
      ))}
    </Container>
  );
};

export default Products;