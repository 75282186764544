import styled from "styled-components";
import { mobile } from "../responsive";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { publicRequest } from "../requestMethods";
import { products } from "../data";

const Container = styled.div``;

const Wrapper = styled.div`
  padding: 50px;
  display: flex;
  ${mobile({ padding: "10px", flexDirection:"column" })}
`;

const ImgContainer = styled.div`
  flex: 1;
`;

const Image = styled.img`
  width: 80%;
  height: auto;
  object-fit: cover;
  ${mobile({ height: "30vh" })}
`;

const InfoContainer = styled.div`
  flex: 1;
  padding: 0px 50px;
  ${mobile({ padding: "10px" })}
`;

const Title = styled.h1`
  font-weight: 200;
`;

const Desc = styled.p`
  margin: 20px 0px;
`;

const Price = styled.span`
  font-weight: 100;
  font-size: 40px;
  color: #50e3c2;
`;

const FilterContainer = styled.div`
  width: 50%;
  margin: 30px 0px;
  display: flex;
  justify-content: space-between;
  ${mobile({ width: "100%" })}
`;

const Filter = styled.div`
  display: flex;
  align-items: center;
`;

const FilterTitle = styled.span`
  font-size: 20px;
  font-weight: 200;
`;


const Product = () => {

  const location = useLocation()
  const id = location.pathname.split("/")[2]
  // const [product, setProduct] = useState({})

  // useEffect(()=>{
  //   const getProduct = async () => {
  //     try {
  //       const res = await publicRequest.get("/products/" + id)

  //       console.log(res)
  //       setProduct(res.data)
  //     } catch (error) {
        
  //     }
  //   }
  //   getProduct()
  // }, [id])

  const currentProduct = products[id-1]

  return (
    <Container>
      <Wrapper>
        <ImgContainer>
          <Image src={currentProduct.img}/>
        </ImgContainer>
        <InfoContainer>
          <Title>{currentProduct.name}</Title>
          <Desc>
            {currentProduct.desc}
          </Desc>
          <Price>Цена: {currentProduct.price} руб</Price>
          <FilterContainer>
            <Filter>
                <FilterTitle>Производитель: <p></p> {currentProduct.manufacturer}</FilterTitle>
            </Filter>
            <Filter>
                <FilterTitle>Фасовка: <p></p> {currentProduct.packing}</FilterTitle>
            </Filter>
          </FilterContainer>
        </InfoContainer>
      </Wrapper>
    </Container>
  );
};

export default Product;