import React from 'react'
import Navbar from './components/Navbar'
import Home from './pages/Home/Home'
import ProductList from './pages/ProductList'
import SingleProduct from './pages/SingleProduct'
import About from './pages/About/About'
import Contact from './pages/Contact/Contact'

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const App = () => {
  return (
    <div>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/products" element={<ProductList/>} />
          <Route path="/products/:id" element={<SingleProduct/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/contacts" element={<Contact/>} />
          {/* <Route path="/login" element={<Login/>} /> */}
          {/* <Route path="/register" element={<Register/>} /> */}
          {/* <Route path="/about" element={<About/>} />
          <Route path="/contacts" element={<Contacts/>} /> */}
        </Routes>
      </Router>
    </div>
  )
}

export default App
