import React from "react";
import styled from "styled-components";
import { mobile } from "../responsive";
import { Link } from "react-router-dom";

const Container = styled.div`
  height: 60px;
  ${mobile({ height: "50px" })}
`;

const Wrapper = styled.div`
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${mobile({ padding: "10px 0px" })}
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const Logo = styled.h1`
  font-weight: bold;
  ${mobile({ fontSize: "24px" })}
`;

const Right = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${mobile({ flex: 2, justifyContent: "center" })}
`;

const MenuItem = styled.div`
  font-size: 20px;
  cursor: pointer;
  margin-left: 30px;
  ${mobile({ fontSize: "12px", marginLeft: "10px" })}
`;

const linkStyle = {
    textDecoration: "none",
    color: 'black'
  };

const Navbar = () => {
    return (
        <Container>
            <Wrapper>
                <Left>
                    <Logo>RUS`KIMYA</Logo>
                </Left>
                <Right>
                    <MenuItem>
                        <Link to="/" className='navLink' style={linkStyle}>Главная</Link>
                    </MenuItem>
                    <MenuItem>
                        <Link to="/products" className='navLink' style={linkStyle}>Ассортимент</Link>
                    </MenuItem>
                    <MenuItem>
                        <Link to="/about" className='navLink' style={linkStyle}>О нас</Link>
                    </MenuItem>
                    <MenuItem>
                        <Link to="/contacts" className='navLink' style={linkStyle}>Контакты</Link>
                    </MenuItem>
                </Right>
            </Wrapper>
        </Container>
    );
};

export default Navbar;