import styled from "styled-components";
import Products from "../components/Products";
import { mobile } from "../responsive";

const Container = styled.div``;

const Title = styled.h3`
  margin: 20px;
  text-align: center;
`;

const ProductList = () => {
  return (
    <Container>
      <Title>Заинтересовал тот или иной товар? Тогда переходите в раздел "Контакты" и сообщите нам любым удобным для вас способом. Мы обязательно свяжемся с Вами!</Title>
      <Products />
    </Container>
  );
};

export default ProductList;