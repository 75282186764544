import React from 'react'
import './about.css'

const About = () => {
  return (
    <div class="post-wrap">

      <div class="post-item">
        <div class="item-content">
          <div class="item-icon tree"></div>
          <div class="item-body">
            <h3>Карточка предприятия</h3>
            <p>Общество с ограниченной ответственностью «РУСЬКИМЯ»</p>
            <p>«RUSKIMYA LLC, Russia Federation,603010,Nizhny Novgorod,Ukrainskaya Street 50-63.</p>
            <p>603010, Нижегородская область, г.Нижний Новгород, ул. Украинская, д.50-63.</p>
            <p>ОГРН: 1195275041785,</p>
            <p>ИНН:5257191405.</p>
            <p>Расчетный счет в рублях РФ: 40702810600040000211 в Филиал АКБ «Славия»(АО) в г.Нижний Новгород.</p>
            <p>Расчетный счет в дирхамах ОАЭ: №40702784600040000211 в Филиал АКБ «Славия»(АО) в г.Нижний Новгород.</p>
            <p>БИК 042202891.</p>
            <p>К-/сч 30101810000000000891.</p>
            <p>E/mail: ruskimya@inbox.ru. тел. +79307001144. +7(831)2811965</p>
            <p>Склад отгрузки: г.Нижний Новгород, Восточный проезд 11.</p>
            <p></p>
            <p></p>
            <p>Генеральный директор ООО»РУСЬКИМЯ» : Аббасов Видади Тахмаз оглы</p>
            <p>Главный бухгальтер: Бурнаева Анастасия Владимировна</p>
            <p>Менеджер по продажам: Бутерманова Светлана Максимовнаа</p>
          </div>
        </div>
      </div>

      <div class="post-item">
        <div class="item-content">
          <div class="item-icon tree"></div>
          <div class="item-body">
            <h3>С кем и откуда?</h3>
            <p>Мы работаем напрямую с заводами-производителями: ПАО «Сибур Холдинг», ПАО «ХИМПРОМ», ПАО «КАЗАНЬОРГСИНТЕЗ», АО «Аммоний», «AZERKIMYA», «Cloran Chemical PC» и др.
              Несмотря на то, что мы молодая компания, мы стремительно наращиваем географию наших поставок.
              Мы готовы импортировать из ИРАНА,ТУРЦИИ и КИТАЯ химическую продукцию по вашим заявкам в
              самые короткие сроки.</p>
          </div>
        </div>
      </div>

      <div class="post-item">
        <div class="item-content">
          <div class="item-icon tree"></div>
          <div class="item-body">
            <h3>Стратегия</h3>
            <p>1. Поставка качественного химического сырья, в т.ч. импортного, производителям химической
              продукции России на постоянной основе, оптимизируя логистические цепи поставок.</p>
            <p>2. Расширение географии экспортных поставок химической продукции российских
              производителей на зарубежные рынки.</p>
          </div>
        </div>
      </div>

      <div class="post-item">
        <div class="item-content">
          <div class="item-icon tree"></div>
          <div class="item-body">
            <h3>Кому?</h3>
            <p>Компания ООО «РУСЬКИМЯ» сейчас поставляет сырьё для разных сфер производства:</p>
            <p>-Производства строительных материалов</p>
            <p>-Химическая промышленность</p>
            <p>-Бытовая химия</p>
            <p>-Металлургия</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
