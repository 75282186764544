
import { Link } from "react-router-dom";
import styled from "styled-components";

const Info = styled.div`
    position: absolute;
    bottom: 0;
  `;

const Container = styled.div`
    flex: 1;
    margin: 5px;
    min-width: 280px;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5fbfd;
    position: relative;
  
    &:hover ${Info}{
      opacity: 1;
    }
  `;

const Image = styled.img`
    height: 75%;
  `;

const Icon = styled.div`
    width: 100%;
    height: 20px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    transition: all 0.5s ease;
    &:hover {
      background-color: #e9f5f5;
      transform: scale(1.1);
    }
  `;

const Product = ({ item }) => {
    return (
        <Container>
            <Image src={item.img} />
            <Info>
                <Icon>
                  <Link style={{
                      textDecoration: "none", 
                      textAlign: "center", 
                      color: "black",
                      backgroundColor: "#E0FFFF"
                    }} 
                    to={`/products/${item.id}`}>
                    {item.name}
                  </Link>
                </Icon>
            </Info>
        </Container>
    );
};

export default Product;